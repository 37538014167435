@file:Suppress("MaxLineLength")

package empiresmp.feature.main.ui.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import empiresmp.components.FilledText
import empiresmp.components.PlatformExt.isMobilePlatform
import org.jetbrains.compose.web.css.percent
import ru.astrainteractive.kobwebx.core.resource.Resources
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun MainTitlePage() {
    val theme = LocalSitePalette.current
    Box(
        modifier = Modifier.width(100.percent),
        contentAlignment = Alignment.Center,
    ) {
        Image(Resources.divBanner.path, modifier = Modifier.width(100.percent))
        Column(
            Modifier
                .thenIf(isMobilePlatform, Modifier.maxWidth(90.percent))
                .thenIf(!isMobilePlatform, Modifier.maxWidth(40.percent))
                .backgroundColor(theme.colors.colorPrimary)
                .padding(theme.dimens.L)
                .borderRadius(theme.dimens.S),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                "Ничего лишнего",
                theme.typography.h3.toModifier()
                    .color(theme.colors.colorOnPrimary)
            )
            SpanText(
                "Выживайте, стройте империю, добывайте новые ресурсы, исследуйте новые механики, торгуйте и знакомьтесь с новыми людьми",
                theme.typography.h3.toModifier()
                    .color(theme.colors.colorOnPrimary)
                    .then(
                        Modifier.textAlign(TextAlign.Center)
                    )
            )
            FilledText(
                "play.EmpireProjekt.ru",
                theme.colors.colorSecondary,
                theme.colors.colorOnSecondary
            )
            SpanText(
                "Мы всегда на самой последней Java версии игры",
                theme.typography.h5.toModifier()
                    .color(theme.colors.colorOnPrimary)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(TextAlign.Center)
            )
            SpanText(
                "Зайти можно с любой версии",
                theme.typography.h5.toModifier()
                    .color(theme.colors.colorOnPrimary)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(TextAlign.Center)
            )
        }
    }
}
