package ru.astrainteractive.kobwebx.core.theme.palette

import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.typography.Body1
import ru.astrainteractive.kobwebx.core.theme.typography.Body1Mobile
import ru.astrainteractive.kobwebx.core.theme.typography.Body2
import ru.astrainteractive.kobwebx.core.theme.typography.Body2Mobile
import ru.astrainteractive.kobwebx.core.theme.typography.H1
import ru.astrainteractive.kobwebx.core.theme.typography.H1Mobile
import ru.astrainteractive.kobwebx.core.theme.typography.H2
import ru.astrainteractive.kobwebx.core.theme.typography.H2Mobile
import ru.astrainteractive.kobwebx.core.theme.typography.H3
import ru.astrainteractive.kobwebx.core.theme.typography.H3Mobile
import ru.astrainteractive.kobwebx.core.theme.typography.H5
import ru.astrainteractive.kobwebx.core.theme.typography.H5Mobile

@Suppress("LongParameterList")
sealed class SitePalette(
    val colors: Colors,
    val defaultTypography: Typography = Typography(
        h1 = H1,
        h2 = H2,
        h3 = H3,
        h5 = H5,
        body1 = Body1,
        body2 = Body2
    ),
    val mobileTypography: Typography = Typography(
        h1 = H1Mobile,
        h2 = H2Mobile,
        h3 = H3Mobile,
        h5 = H5Mobile,
        body1 = Body1Mobile,
        body2 = Body2Mobile
    ),
    val dimens: Dimens = Dimens(
        XS = 4.px,
        S = 8.px,
        M = 12.px,
        L = 16.px,
        XL = 22.px,
        XXL = 28.px
    ),
    val isDark: Boolean
)
