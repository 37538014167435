package empiresmp.components.navbar

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.aspectRatio
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.GeneralKind
import com.varabyte.kobweb.silk.style.toModifier
import empiresmp.components.ImageTextLink
import empiresmp.components.PlatformExt
import empiresmp.components.TextLink
import org.jetbrains.compose.web.css.percent
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
internal fun AdaptiveLink(
    path: String,
    text: String,
    iconPath: String,
    fontStyle: CssStyle<GeneralKind> = LocalSitePalette.current.typography.h2,
) {
    val theme = LocalSitePalette.current
    if (!PlatformExt.isMobilePlatform) {
        TextLink(
            path = path,
            text = text,
            modifier = fontStyle
                .toModifier()
                .then(Modifier.color(theme.colors.colorOnPrimary))
        )
    } else {
        Box(
            Modifier
                .margin(theme.dimens.S)
                .borderRadius(100.percent)
                .backgroundColor(theme.colors.colorSecondaryVariant)
                .background(theme.colors.colorSecondaryVariant)
                .color(theme.colors.colorSecondaryVariant)
                .aspectRatio(1),
            contentAlignment = Alignment.Center
        ) {
            ImageTextLink(
                path = path,
                text = text.takeIf { !PlatformExt.isMobilePlatform }.orEmpty(),
                modifier = fontStyle
                    .toModifier()
                    .then(Modifier.color(theme.colors.colorOnPrimary)),
                iconPath = iconPath
            )
        }
    }
}
