package empiresmp.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun AdaptiveRow(
    content: @Composable () -> Unit
) {
    val theme = LocalSitePalette.current
    val bp = rememberBreakpoint()
    if (bp > Breakpoint.MD) {
        Row(
            Modifier.fillMaxWidth().padding(theme.dimens.L),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) { content() }
    } else {
        Column(
            Modifier.fillMaxWidth().padding(theme.dimens.S),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceBetween
        ) { content() }
    }
}
