package ru.astrainteractive.kobwebx.core.theme.util

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import ru.astrainteractive.kobwebx.core.theme.palette.SitePalette
import ru.astrainteractive.kobwebx.core.theme.palette.Typography

object ThemeExt {
    val SitePalette.typography: Typography
        @Composable
        get() {
            val bp = rememberBreakpoint()
            return when {
                (bp <= Breakpoint.MD) -> mobileTypography
                else -> defaultTypography
            }
        }
}
