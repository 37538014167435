package ru.astrainteractive.kobwebx.core.theme.palette

import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue

@Suppress("ConstructorParameterNaming")
class Dimens(
    val XS: CSSLengthOrPercentageValue,
    val S: CSSLengthOrPercentageValue,
    val M: CSSLengthOrPercentageValue,
    val L: CSSLengthOrPercentageValue,
    val XL: CSSLengthOrPercentageValue,
    val XXL: CSSLengthOrPercentageValue
)
