package ru.astrainteractive.kobwebx.core.theme.composable

import androidx.compose.runtime.compositionLocalOf
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.silk.components.layout.HorizontalDividerStyle
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.modifyStyleBase
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.model.ThemeType
import ru.astrainteractive.kobwebx.core.theme.palette.DarkSitePalette
import ru.astrainteractive.kobwebx.core.theme.palette.LightSitePalette
import ru.astrainteractive.kobwebx.core.theme.palette.SitePalette
import ru.astrainteractive.kobwebx.core.theme.presentation.ThemeKrate

val LocalSitePalette = compositionLocalOf {
    ThemeKrate.stateFlow.value.toSitePalette()
}

fun ThemeType.toSitePalette(): SitePalette {
    return when (this) {
        ThemeType.DARK -> DarkSitePalette
        ThemeType.LIGHT -> LightSitePalette
    }
}

fun ThemeType.toColorMode() = when (this) {
    ThemeType.DARK -> ColorMode.DARK
    ThemeType.LIGHT -> ColorMode.LIGHT
}

fun ColorMode.toThemeType() = when (this) {
    ColorMode.DARK -> ThemeType.DARK
    ColorMode.LIGHT -> ThemeType.LIGHT
}

@InitSilk
fun initColorMode(ctx: InitSilkContext) {
    ctx.config.initialColorMode = ThemeKrate.stateFlow.value.toColorMode()
}

@InitSilk
fun initTheme(ctx: InitSilkContext) {
    ctx.theme.palettes.light.background = Color.Companion.argb(0xFF555555) // SitePalette.Light.colorPrimaryDark
    ctx.theme.palettes.light.color = Color.Companion.argb(0xFF555555) // SitePalette.Light.colorOnPrimary

    ctx.theme.palettes.dark.background = Color.Companion.argb(0xFF555555) // SitePalette.Dark.colorPrimaryDark
    ctx.theme.palettes.dark.color = Color.Companion.argb(0xFF555555) // SitePalette.Dark.colorOnPrimary
}

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("body") {
        Modifier
            .fontFamily(
                "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
                "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"
            )
            .fontSize(18.px)
            .lineHeight(1.5)
    }

    // Silk dividers only extend 90% by default; we want full width dividers in our site
    ctx.theme.modifyStyleBase(HorizontalDividerStyle) {
        Modifier.fillMaxWidth()
    }
}
