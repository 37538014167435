package empiresmp.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import empiresmp.feature.main.ui.MainPageContent
import empiresmp.feature.votes.ui.MapPageContent

@Page("/map")
@Composable
fun Map() {
    MapPageContent()
}
