package empiresmp.feature.votes.ui

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.zIndex
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import empiresmp.components.LoadingIndicator
import empiresmp.components.layouts.PageLayout
import empiresmp.components.navbar.EmpireSmpDesktopNavBar
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Iframe
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun MapPageContent() {
    val theme = LocalSitePalette.current
    PageLayout {
        EmpireSmpDesktopNavBar()

        Box(
            modifier = Modifier
                .width(100.percent)
                .height(100.percent)
                .borderRadius(theme.dimens.S),
            contentAlignment = Alignment.Center
        ) {
            LoadingIndicator(modifier = Modifier.size(128.px).zIndex(0))
            Iframe(
                attrs = {
                    style {
                        property("border", "none")
                        width(100.percent)
                        height(100.percent)
                        borderRadius(theme.dimens.S)
                        zIndex(1)
                    }
                    attr("allowfullscreen", "true")
                    attr("allow", "autoplay; fullscreen")
                    attr("src", "https://map.astrainteractive.ru")
                },
            )
        }
    }
}
