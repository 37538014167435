import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "Empire Projekt"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { empiresmp.pages.Index() }
        ctx.router.register("/map") { empiresmp.pages.Map() }
        ctx.router.register("/votes") { empiresmp.pages.Votes() }
        ctx.router.register("/wiki") { empiresmp.pages.WikiPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("loader", empiresmp.components.LoaderStyle)
        ctx.theme.registerStyle("href-card", empiresmp.components.hrefcard.HrefCardStyle)
        ctx.theme.registerStyle("h1", ru.astrainteractive.kobwebx.core.theme.typography.H1)
        ctx.theme.registerStyle("h2", ru.astrainteractive.kobwebx.core.theme.typography.H2)
        ctx.theme.registerStyle("h1-mobile",
                ru.astrainteractive.kobwebx.core.theme.typography.H1Mobile)
        ctx.theme.registerStyle("h2-mobile",
                ru.astrainteractive.kobwebx.core.theme.typography.H2Mobile)
        ctx.theme.registerStyle("h3", ru.astrainteractive.kobwebx.core.theme.typography.H3)
        ctx.theme.registerStyle("h3-mobile",
                ru.astrainteractive.kobwebx.core.theme.typography.H3Mobile)
        ctx.theme.registerStyle("h5", ru.astrainteractive.kobwebx.core.theme.typography.H5)
        ctx.theme.registerStyle("h5-mobile",
                ru.astrainteractive.kobwebx.core.theme.typography.H5Mobile)
        ctx.theme.registerStyle("body1", ru.astrainteractive.kobwebx.core.theme.typography.Body1)
        ctx.theme.registerStyle("body2", ru.astrainteractive.kobwebx.core.theme.typography.Body2)
        ctx.theme.registerStyle("body1-mobile",
                ru.astrainteractive.kobwebx.core.theme.typography.Body1Mobile)
        ctx.theme.registerStyle("body2-mobile",
                ru.astrainteractive.kobwebx.core.theme.typography.Body2Mobile)
        ctx.theme.registerKeyframes("spin", empiresmp.components.Spin)
        ru.astrainteractive.kobwebx.core.theme.composable.initColorMode(ctx)
        ru.astrainteractive.kobwebx.core.theme.composable.initTheme(ctx)
        ru.astrainteractive.kobwebx.core.theme.composable.initSiteStyles(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        empiresmp.Application {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
