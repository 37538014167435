package empiresmp.components.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import empiresmp.components.PlatformExt.isMobilePlatform
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.dom.Footer
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun EmpireFooter() {
    val theme = LocalSitePalette.current
    val marginBottom = if (isMobilePlatform) 6.em else 0.em
    Footer(Modifier.fillMaxWidth().margin(bottom = marginBottom).toAttrs()) {
        Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
            SpanText(
                "Made with KobWeb",
                theme.typography.h5.toModifier().color(theme.colors.colorOnPrimary).cursor(Cursor.Text)
            )
        }
    }
}
