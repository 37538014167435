package ru.astrainteractive.kobwebx.core.theme.palette

import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.GeneralKind

class Typography(
    val h1: CssStyle<GeneralKind>,
    val h2: CssStyle<GeneralKind>,
    val h3: CssStyle<GeneralKind>,
    val h5: CssStyle<GeneralKind>,
    val body1: CssStyle<GeneralKind>,
    val body2: CssStyle<GeneralKind>,
)
